const contactData = [
    {
        id: 1,
      name: "Anya Forger",
      email: "anya123@gmail.com",
      contactNo: "+6012-3456789",
      message:  "Hello There! Is your branch located in Kuala Lumpur?",
      postingDate:  "2022-11-07 22:48:57",
      active:   true
    }
  ];

  export default contactData;